<template>
	<v-chart v-if="show" class="chart" :option="option" />
</template>

<script setup>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart, BarChart, PieChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { provide, watch, reactive, ref } from 'vue'

use([
	CanvasRenderer,
	LineChart,
	BarChart,
	PieChart,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
])

provide(THEME_KEY, 'light')

const show = ref(false)
// eslint-disable-next-line
const props = defineProps({
	option: {
		type: Object,
		default: () => {},
	},
})
const option = reactive(props.option)

const render = () => {
	setTimeout(() => {
		show.value = true
	}, 0)
}

watch(
	() => props.option.series.length,
	() => {
		show.value = false
		option.series = [...props.option.series]
		render()
	},
)
render()
</script>

<style scoped>
.chart {
	width: 100%;
	height: 100%;
}
</style>
